<template>
  <div class="container_login">
    <form @submit.prevent="sendform">
      <router-link :to="{ name: 'home' }">
        <div class="container_login__logo mb-3">
          <div class="container_login__logo--img">
            <img src="@/assets/img/do-genius-on-white.svg" class="img-fluid">
          </div>
          <div class="container_login__logo--text">
            <h5>
              BUILDING <br>
              TECHNOLOGY <br>
              FOR TECHNOLOGY <br>
              BUILDERS
            </h5>
          </div>
        </div>
      </router-link>
      <div class="mb-3">
        <input type="password" placeholder="Enter new password" class="form-control"
              :class="{'is-invalid': $v.form.password.$error}"
              v-model.trim="$v.form.password.$model">
        <span role="alert" class="invalid-feedback" v-if="$v.form.password.$error">
          <strong v-if="!$v.form.password.required">The password is required</strong>
        </span>
        <span class="validation_password mt-1" v-if="form.password != '' && form.password != null">
          <span class="validation_password--text" :class="[checkPassword.level]">
            {{checkPassword.title}}
          </span>
          <span class="validation_password--description">
            {{checkPassword.description}}
          </span>
        </span>
      </div>
      <div class="mb-3">
        <input type="password" placeholder="Enter confirm new password" class="form-control"
              :class="{'is-invalid': $v.form.password_confirmation.$error}"
              v-model.trim="$v.form.password_confirmation.$model">
        <span role="alert" class="invalid-feedback" v-if="$v.form.password_confirmation.$error">
          <strong v-if="!$v.form.password_confirmation.required">The confirm new password is required</strong>
          <strong v-if="!$v.form.password_confirmation.sameAsPassword && $v.form.password_confirmation.required">The password confirmation does not match</strong>
        </span>
      </div>
      <div class="container_login__form--btn mb-3">
          <button type="submit" class="btn">Reset password</button>
      </div>
      <div class="container_login__form--link">
        <router-link :to="{ name: 'login' }">
          Back to login
        </router-link>
      </div>
    </form>
    <Footer/>
    <b-overlay :show="load" rounded="sm" no-wrap></b-overlay>
  </div>
</template>

<script>
import {required, sameAs, minLength, maxLength} from 'vuelidate/lib/validators';

import Footer from '@/components/FooterNewsRoom.vue';

export default {
  name: 'reset_password',
  components: {
    Footer,
  },
  data() {
    return {
      form: {
        password: null,
        password_confirmation: null
      },
      load: false,
    };
  },
  beforeCreate() {
    if (this.$session.exists()) {
      this.$router.push({ name: 'dashboard' });
    }

    this.$http.get(`password/reset/${this.$route.params.token}`).then((response) => {

    }, (error) => {
      if (error.status == 500 || error.status == 419) {
        this.$router.push({ name: 'page.419' });
      }
    })
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(60),
        isValid(value){
          return this.checkLevelPassword(value).success;
        }
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      },
    },
  },
  computed: {
    checkPassword(){
      return this.checkLevelPassword(this.form.password);
    }
  },
  methods: {
    sendform() {
      this.$v.form.$reset();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.load = true;
        this.$http.post(`password/reset/${this.$route.params.token}`, this.form).then((response) => {
          if (response.status == 200) {
            this.$notify({ group: 'app', text: response.body.message, type: 'success', duration: 100000 });
            this.$router.push({ name: 'login' });
          }
          this.load = false;
        }, (error) => {
          console.log(error)
          if (error.status === 500) {
            this.$notify({ group: 'app', text: 'An error occurred while trying to reset password. Try again.', type: 'error' });
          }
          if (error.status === 400) {
            if (error.body.response == 'error_form') {
              this.$notify({ group: 'app', text: error.body.message, type: 'warn' });
            }
          }
          if (error.status == 419) {
            this.$router.push({ name: 'page.419' });
          }
          this.load = false;
        })
      }
    },
  },
};
</script>
